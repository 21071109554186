body {
  margin: 0;
  text-align: center;
  font-size: 1em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

h1 {
  font-size: 1.2em;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
  margin: 0 0 30px 0;
  color: #333;
}

.row {
  text-align: center;
  display: flex;
  justify-content: center;
}

.row>div {
  display: block;
  width: 60px;
  height: 60px;
  border: 1px solid #bbb;
  margin: 4px;
  text-align: center;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.5em;
  color: #000;
}

.row>div.green {
  --background: #4f864a;
  --border-color: #4f864a;
  animation: flip 0.5s ease forwards;
}

.row>div.yellow {
  --background: #b59f3b;
  --border-color: #b59f3b;
  animation: flip 0.5s ease forwards;
}

.row>div.grey {
  --background: #3a3a3c;
  --border-color: #3a3a3c;
  animation: flip 0.5s ease forwards;
}

.row>div:nth-child(2) {
  animation-delay: 0.2s;
}

.row>div:nth-child(3) {
  animation-delay: 0.4s;
}

.row>div:nth-child(4) {
  animation-delay: 0.6s;
}

.row>div:nth-child(5) {
  animation-delay: 0.8s;
}

.row.current>div.filled {
  animation: bounce 0.2s ease-in-out forwards;
}

.keypad {
  max-width: 500px;
  margin: 20px auto;
}

.keypad>div {
  margin: 5px;
  width: 40px;
  height: 50px;
  background: #eee;
  display: inline-block;
  border-radius: 6px;
  line-height: 50px;
}

.keypad>div.green {
  background: #4f864a;
  color: #fff;
  transition: all 0.3s ease-in;
}

.keypad>div.yellow {
  background: #b59f3b;
  color: #fff;
  transition: all 0.3s ease-in;
}

.keypad>div.grey {
  background: #3a3a3c;
  color: #fff;
  transition: all 0.3s ease-in;
}

.modal {
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.modal div {
  max-width: 480px;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  margin: 10% auto;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.modal .solution {
  color: #ff004c;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@keyframes flip {
  0% {
    transform: rotateX(0);
    background: #fff;
    border-color: #333;
  }

  45% {
    transform: rotateX(90deg);
    background: #fff;
    border-color: #333;
  }

  55% {
    transform: rotateX(90deg);
    background: var(--background);
    border-color: var(--border-color);
  }

  100% {
    transform: rotateX(0);
    background: var(--background);
    border-color: var(--border-color);
    color: #eee
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
    border-color: #ddd;
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
    border-color: #333;
  }
}